const Dashboard = '/dashboard';
const PollutionMap = '/pollutionmap';
const PollutionVariation = '/pollutionvariation';
const Statistics = '/statistics';
const Cams = '/cams';
const TermsOfUse = '/termsofuse';

export const StaticRoutes = {
	Dashboard, PollutionMap, PollutionVariation, Statistics, Cams, TermsOfUse
};

export const routes = [
	{
		url: Dashboard,
		name: 'dashboard',
		component: 'Dashboard'
	},
	{
		url: PollutionMap,
		name: 'pollutionmap',
		component: 'PollutionMap'
	},
	{
		url: PollutionVariation,
		name: 'pollutionvariation',
		component: 'PollutionVariation'
	},
	{
		url: Statistics,
		name: 'statistics',
		component: 'Statistics'
	},
	{
		url: Cams,
		name: 'cams',
		component: 'Cams'
	},
	{
		url: TermsOfUse,
		name: 'termsofuse',
		component: 'TermsOfUse'
	},
];

const combined = {StaticRoutes, routes};

export default combined;
