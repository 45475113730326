import React, { Component } from 'react';
import { connect } from 'react-redux';


class TermsOfUse extends Component {

    render() {

        return (
            <div>
                <h1 style={{textAlign: 'center'}}>Όροι Χρήσης</h1>
                <p>Καλωσήρθατε στον διαδικτυακό airquality.agrinio.gov.gr (όπου από εδώ και στο εξής θα αναφέρεται ως «Ιστοσελίδα»), διαχειριζόμενο μέσω του έργου «Περιβαλλοντικό Παρατηρητήριο Δήμου Αγρινίου» (όπου από εδώ και στο εξής θα αναφέρεται ως «Έργο», «Εμείς», «μας» και «εμάς»).</p>
                <p>Το έργο έχει λάβει χρηματοδότηση από το Δήμο Αγρινίου.</p>
                <p>Μέσω της πρόσβασης ή χρήσης της Ιστοσελίδας, με οποιοδήποτε τρόπο, συμφωνείτε με τους όρους Χρήσης της, συμπεριλαμβανομένων εγγράφων, πολιτικών και οδηγιών, που ενσωματώνονται με αναφορά. Οι όροι «Χρήστης», «επισκέπτης», «εσείς», «δικός σου» ή «δικός σας» αναφέρονται σε καθένα από τους Χρήστες της Ιστοσελίδας, καθώς και σε συνεργάτες και εργαζομένους τους. Οι συγκεκριμένοι Όροι δεν αλλοιώνουν με οποιοδήποτε τρόπο τους όρους και τις προϋποθέσεις της κάθε έγγραφης συμφωνίας, την οποία μπορεί να έχετε με το Έργο.</p>
                <p>Στην περίπτωση που δεν συμφωνείτε με αυτούς τους Όρους και τις προϋποθέσεις, πρέπει να αποφύγετε την πρόσβαση στην Ιστοσελίδα ή την χρήση του περιεχομένου ή των υπηρεσιών της.</p>
                <p>Οι πληροφορίες, οι οποίες παρέχονται στην ιστοσελίδα, έχουν ως μοναδικό σκοπό την παροχή πληροφοριών για το έργο, τους αντικειμενικούς στόχους του, τα αποτελέσματα, ειδήσεις, δραστηριότητες, γεγονότα και την ομάδα εργασίας. Παρόλο που εμείς προσπαθούμε να διατηρούμε όλες τις πληροφορίες ακριβείς και ενημερωμένες, το περιεχόμενο της Ιστοσελίδας δεν συνιστά σε καμία περίπτωση συμβουλή, πρόταση, αντιπροσώπευση, εγγύηση ή προσφορά, είτε οικονομική είτε άλλη, εκ μέρους του Έργου.</p>
                <p>Το Έργο δεν εγγυάται καθ’ οιονδήποτε τρόπο την πληρότητα ή ακρίβεια των πληροφοριών. Όλες οι πληροφορίες μπορούν να αλλάξουν ανά πάσα στιγμή, χωρίς προειδοποίηση. Μέσω της πρόσβασης στην Ιστοσελίδα, συμφωνείτε ρητά ότι, το Έργο δεν φέρει καμία ευθύνη για έμμεση ή άμεση ζημιά, η οποία μπορούσε να προκύψει από την χρήση ή ερμηνεία της πληροφορίας και υλικού, το οποίο περιέχεται στην Ιστοσελίδα ή άλλες Ιστοσελίδες που συνδέονται με αυτήν.</p>
                <p>Όλοι οι σύνδεσμοι, εντός και εκτός διαδικτύου, παρέχονται σε γενικές γραμμές ως εργαλεία διευκόλυνσης και αναφοράς. Το Έργο δεν πραγματοποιεί κανένα έλεγχο σε ιστοσελίδες τρίτων και με καθ’ οιονδήποτε τρόπο δεν υποστηρίζει αυτές τις ιστοσελίδες ή τους ιδιοκτήτες τους, ούτε είναι υπεύθυνο για το περιεχόμενο τους. Το Έργο αποποιείται όλες τις εγγυήσεις, σχετικά με, την ακρίβεια, ακεραιότητα και καταλληλόλητα της πληροφορίας, παρεχόμενης από άλλες ιστοσελίδες, συμπεριλαμβανομένων όλων των ρητών ή μη εγγυήσεων, μην αναλαμβάνοντας επίσης καμία ευθύνη.</p>

                <h4>Αλλαγές στους όρους Χρήσης της Ιστοσελίδας</h4>
                <p>Χρησιμοποιώντας την Ιστοσελίδα, συμφωνείτε με αυτούς τους όρους Χρήσης και την σχετική Πολιτική Απορρήτου. Το Έργο διατηρεί το δικαίωμα να τροποποιήσει και/ή αναθεωρήσει αυτούς τους Όρους Χρήσης και την Πολιτική Απορρήτου των Προσωπικών δεδομένων κάθε στιγμή, χωρίς καμία προηγούμενη προειδοποίηση. Οι αναθεωρημένοι όροι θα ισχύουν αμέσως μόλις, αυτοί αναρτηθούν στην Ιστοσελίδα.</p>
                <p>Επιπρόσθετα, το Έργο διατηρεί το δικαίωμα να αλλάξει, τροποποιήσει ή να διακόψει, προσωρινά ή μόνιμα την Ιστοσελίδα (ή οποιοδήποτε τμήμα αυτής). Πληροφορίες για κάθε μία από τις αλλαγές θα είναι διαθέσιμη στην Ιστοσελίδα, και για την συνέχεια της χρήση της απαιτείται η αποδοχή αυτών των αλλαγών.</p>

                <h4>Εγγραφή στο Δελτίο για λήψη Ενημερώσεων</h4>
                <p>Οι χρήστες έχουν το δικαίωμα να ανανεώσουν, τροποποιήσουν, σβήσουν και να κάνουν αίτηση για έκδοση ενός αντιγράφου των προσωπικών στοιχείων τους και αυτές τις ενέργειες δεσμεύεται να τις τηρεί το Έργο. Εάν ένας Χρήστης θέλει να ανανεώσει, σβήσει ή να λάβει ένα αντίγραφο των προσωπικών του/της στοιχείων, αυτός/αυτή μπορούν να στείλουν ένα e-mail στο admin@agrinio.gr. Οι ανανεώσεις, τροποποιήσεις ή διαγραφές προσωπικών δεδομένων θα ισχύσουν μετά τον χρόνο που απαιτείται για την ολοκλήρωση της σχετικής διαδικασίας.</p>
                <p>Η ασφάλεια των προσωπικών δεδομένων σας είναι πολύ σημαντική για εμάς, και γι’ αυτό τον λόγο, εμείς παίρνουμε όλα τα απαραίτητα μέτρα ασφαλείας, με βάση τις καλύτερες διεθνείς πρακτικές, με στόχο να εξασφαλίσουμε την ασφάλεια των προσωπικών δεδομένων σου, και να απαγορεύσουμε οποιαδήποτε παράνομη πρόσβαση σε αυτά.</p>

                <h4>Ακύρωση Συνδρομής, Αναστολή ή Περάτωση</h4>
                <p>Οι χρήστες έχουν το δικαίωμα να ακυρώσουν την εγγραφή τους κάθε στιγμή στο ενημερωτικό δελτίο Newsalert, στέλνοντας ένα e-mail στο admin@agrinio.gr. Η ακύρωση της εγγραφής από το Ενημερωτικό Δελτίο θα τεθεί σε ισχύ μετά τον χρόνο που απαιτείται για την ολοκλήρωση της σχετικής διαδικασίας. Το Έργο διατηρεί το δικαίωμα να αναστείλει ή τερματίσει οποιαδήποτε από τις εγγραφές στο ενημερωτικό δελτίο Newsalert, κάθε στιγμή και χωρίς καμία προειδοποίηση. Πιθανοί λόγοι για οποιαδήποτε τέτοια αναστολή περιλαμβάνουν, αλλά δεν περιορίζονται μόνο (α) στη παράβαση ή παραβίαση ενσωματωμένων συμφωνιών ή οδηγιών, (β) αιτήματα, τα οποία σχετίζονται με την επιβολή του νόμου ή άλλες κυβερνητικές υπηρεσίες, (γ) μη εξουσιοδοτημένη τροποποίηση ή κατάργηση υλικού στην Ιστοσελίδα μας, (δ) μη αναμενόμενα τεχνικά προβλήματα ή θέματα ασφαλείας και (ε) συμμέτοχη σε παράνομες δραστηριότητες από τον συνδρομητή της Ιστοσελίδας.</p>

                <h4>Προστασία των σημάτων της υπηρεσίας, λογοτύπων και εμπορικών σημάτων</h4>
                <p>Τα εμπορικά σήματα του Έργου, τα οποία χρησιμοποιούνται ή εκτίθενται στην Ιστοσελίδα του Έργου ή από τρίτα μέρη, προστατεύονται από όλα τα απαραίτητα πνευματικά δικαιώματα.</p>
                <p>Το Έργο διαθέτει όλα τα νόμιμα πνευματικά δικαιώματα για ολόκληρο το περιεχόμενο και υλικό της Ιστοσελίδας, συμπεριλαμβανομένου των λογοτύπων, των εμπορικών σημάτων, των κεφαλίδων της σελίδας, των εικονιδίων για τα κουμπιά, εικόνων, γραφικών, videos, των προσαρμοσμένων γραφικών, κειμένων και του υλικού, στο οποίο υπάρχει δυνατότητα λήψης.</p>
                <p>Κάθε εμπορικό σήμα τρίτου μέρους, σήμα υπηρεσίας, ονόματος του προϊόντος, ονόματος της υπηρεσίας, ονόματος του έργου ή του λογοτύπου, τα οποία μπορούν να εμφανιστούν στην Ιστοσελίδα, αποτελούν ιδιοκτησία των κατόχων τους, οι οποίοι ενδέχεται ή μη να σχετίζονται με το Έργο μας και δεν παρέχεται άδεια για την χρήση και την αναπαραγωγή τους.</p>

                <h4>Ασφάλεια Ιστοσελίδας</h4>
                <p>Η παραβίαση της ασφάλειας της Ιστοσελίδας απαγορεύεται και μπορεί να οδηγήσει στον καταλογισμό ποινικών και αστικών ευθυνών. Εάν υποπτευθούμε οποιαδήποτε εγκληματική παραβίαση, εμείς έχουμε το δικαίωμα να την διερευνήσουμε και να συνεργαστούμε με τις αρχές για την επιβολή του νόμου.</p>
                <p>Μερικά παραδείγματα παραβιάσεων της Ιστοσελίδας είναι, χωρίς περιορισμό, η μη εξουσιοδοτημένη πρόσβαση ή χρήση δεδομένων του συστήματος, συμπεριλαμβανομένης κάθε προσπάθειας, που στόχο έχει να ελέγξει την ευπάθεια της Ιστοσελίδας, την παραβίαση μέτρων ασφαλείας ή ελέγχου ταυτότητας, τη μη εξουσιοδοτημένη παρακολούθηση δεδομένων, την ανεξέλεγκτη και χωρίς λόγο αποστολή πολλαπλών μηνυμάτων μέσω ηλεκτρονικού ταχυδρομείου (email), τις σκόπιμες προσπάθειες υπερφόρτωσης του Συστήματος και τη χρήση οποιουδήποτε χειροκίνητου ή ηλεκτρονικού μέσου για αποφυγή οποιονδήποτε περιορισμών, που επιβάλλονται από το Έργο στους Χρήστες της Ιστοσελίδας.</p>

                <h4>Περιορισμός της ευθύνης-Αποποίηση των εγγυήσεων</h4>
                <p>Η αποκλειστική ευθύνη βαρύνει τους συντάκτες μας για το περιεχόμενο της Ιστοσελίδας. Αυτό δεν αντικατοπτρίζει απαραίτητα τη γνώμη του Δήμου Αγρινίου. Ο Δήμος Αγρινίου δεν είναι υπεύθυνος για οποιαδήποτε χρήση των πληροφοριών, που εμπεριέχονται στην Ιστοσελίδα. Το Έργο, η ομάδα εργασίας της και οι συντάκτες δεν μπορούν να θεωρηθούν υπεύθυνοι για την χρήση οποιονδήποτε πληροφοριών, εμπεριέχονται εντός ή συνδέονται με την Ιστοσελίδα του έργου.</p>
                <p>Ο επισκέπτης του παρόντος κόμβου αποδέχεται ότι η χρήση των πληροφοριών που παρέχονται γίνεται με δική του ευθύνη και δεν παρέχεται καμία εγγύηση ότι οι πληροφορίες που διατίθενται είναι πάντα σωστές. Παρόλες τις συνεχείς βελτιώσεις που γίνονται στον τομέα των ατμοσφαιρικών μετρήσεων, αυτές μπορεί να έχουν λάθη και συνοδεύονται πάντα από αβεβαιότητες. Ο επισκέπτης πρέπει πάντα να λαμβάνει υπόψη του την πιθανότητα λανθασμένης μέτρησης. O δείκτης ποιότητας αέρα βασίζεται στις μετρήσεις των μικρών αιωρούμενων σωματιδίων. Όταν η ατμόσφαιρα έχει πολύ μεγάλες συγκεντρώσεις μεγάλων σωματιδίων (π.χ. αφρικανική σκόνη), οι τιμές του δείκτη ενδέχεται να μην αντιπροσωπεύουν τις συνθήκες ποιότητας αέρα.</p>
                <p>Το Έργο, η ομάδα εργασίας της και οι πάροχοι της υπηρεσίας αποποιούνται, υπό οποιεσδήποτε συνθήκες, των ευθυνών για κάθε άμεση, έμμεση, ειδική, τυχαία ή τιμωρητική ζημιά, συμπεριλαμβανομένων ενδεικτικά και της απώλειας δεδομένων, κερδών ή χρημάτων, ή κάθε άλλης ζημιάς σχετιζόμενης με τη χρήση της Ιστοσελίδας.</p>
                <p>Το Έργο δεν ευθύνεται για την καθυστέρηση εκτέλεσης ή για την παράλειψη οποιασδήποτε άλλης δράσης εξαιτίας λόγων ανωτέρας βίας, συμπεριλαμβανομένων χωρίς περιορισμό, των θεϊκών δράσεων, των φυσικών καταστροφών, αποτυχία στις επικοινωνίες, κυβερνητικών ενεργειών, πολέμων, απεργιών, ελλείψεων υλικών και χώρων εργασίας, βανδαλισμού και τρομοκρατίας.</p>
                <p>Η παρούσα αποποίηση ευθύνης εφαρμόζεται σε οποιαδήποτε ζημιά η οποία μπορεί να προκληθεί από οποιοδήποτε σφάλμα ή έλλειψη πληροφόρησης, διακοπή ή καθυστέρηση παροχής των πληροφοριών.</p>
                <p>To περιεχόμενο και οι υπηρεσίες του κόμβου αυτού διατίθενται στους επισκέπτες του κόμβου αυστηρά για προσωπική χρήση. Απαγορεύεται η χρήση τους, σε οποιοδήποτε άλλο μέσο καθώς και για εμπορικούς σκοπούς, χωρίς γραπτή άδεια.</p>
                <p>Σε καμία περίπτωση οποιοσδήποτε φορέας ή πρόσωπο που εμπλέκεται στην παροχή πληροφοριών στον κόμβο αυτό και την εφαρμογή για κινητά έχει ευθύνη για άμεση ή έμμεση ζημιά που μπορεί να προκύψει από τη χρήση του κόμβου ή της εφαρμογής. Ο χρήστης αποδέχεται ότι αυτή η αποποίηση ευθύνης ισχύει για το σύνολο των πληροφοριών που διατίθενται στον κόμβο.</p>
                
                <h4>Πολιτική Απορρήτου</h4>
                <p>Εμείς συμβουλεύουμε δυναμικά εσάς να διαβάσετε την Πολιτική Απορρήτου, η οποία εξηγεί τις πρακτικές που το Έργο χρησιμοποιεί σχετικά με την συλλογή και χρήση των προσωπικών δεδομένων μέσω ή σε σύνδεση με την Ιστοσελίδα.</p>
                <p>Η χρήση των δεδομένων σας από το Έργο διέπεται από την Πολιτική Απορρήτου μας, η οποία σχετίζεται ρητά με αυτούς τους Όρους.</p>
                
                <h4>Διαφορές</h4>
                <p>Κάθε αποτυχία του Έργου να εκπληρώσει ή να επιβάλει οποιαδήποτε διάταξη των όρων, δεν συνιστά σε καμία περίπτωση να εφαρμόζεται ελεύθερα η συγκεκριμένη διάταξη από τους Χρήστες.</p>
                <p>Εάν οποιαδήποτε διάταξη αυτών των Όρων θεωρηθεί παράνομη, άκυρη ή για οποιονδήποτε λόγο μη εφαρμόσιμη, τότε αυτή η διάταξη θα διαχωριστεί από τους υπόλοιπους Όρους και δεν θα επηρεάσει την ισχύ τους.</p>
                <p>α δικαιώματα και οι υποχρεώσεις σας που απορρέουν από αυτούς τους Όρους, ενδέχεται να μη μεταβιβαστούν από εσάς σε τρίτους χωρίς τη γραπτή συγκατάθεση του Έργου. Αυτοί οι όροι δεν αναφέρουν ρητά ή σιωπηρά κάποια αποζημίωση.</p>
                

                <h1 style={{textAlign: 'center'}}>Πολιτική Προστασίας Δεδομένων</h1>
                <p>Το έργο «Περιβαλλοντικό Παρατηρητήριο Δήμου Αγρινίου» (όπου εφεξής θα αναφέρεται στο «Έργο», «εμείς» και «εμάς») παρέχει μια συγκεκριμένη Πολιτική Απορρήτου (εφεξής θα αναφέρεται ως «Πολιτική»), προκειμένου να εξηγήσει στους επισκέπτες της Ιστοσελίδας airquality.agrinio.gov.gr, τις πολιτικές που χρησιμοποιούνται για τη συλλογή, κοινή χρήση, γνωστοποίηση, χρήση και προστασία προσωπικών δεδομένων, που καθίστανται διαθέσιμα από εμάς μέσω της χρήσης της Ιστοσελίδας (εφεξής θα αναφέρεται ως «Ιστοσελίδα» ή «Υπηρεσία») στους επισκέπτες της. Οι όροι «εσείς», «δικός σου», «δικός σας» ή «επισκέπτης» αναφέρονται σε οποιονδήποτε χρήστη ή επισκέπτη της Ιστοσελίδας.</p>
                <p>Τα προσωπικά στοιχεία που συλλέγουμε χρησιμοποιούνται για την παροχή και βελτίωση της υπηρεσίας. Δεν θα χρησιμοποιήσουμε τα συγκεκριμένα δεδομένα για οποιονδήποτε άλλο λόγο και δεν θα τα αποκαλύψουμε ούτε θα τα κοινοποιήσουμε σε τρίτους, εκτός εάν περιγράφεται στην παρούσα Πολιτική Απορρήτου.</p>
                <p>Η παρούσα Πολιτική ισχύει μόνο για τα προσωπικά δεδομένα, που συλλέγονται μέσω της Ιστοσελίδας, και δεν ισχύει για τα δεδομένα που συλλέγονται με διαφορετικούς τρόπους από το Έργο, ή από διαφορετικές Ιστοσελίδες, οι οποίες συνδέονται με την Ιστοσελίδα μας.</p>
                <p>Οι όροι που χρησιμοποιούνται σε αυτήν την Πολιτική Απορρήτου είναι ταυτόσημές έννοιες με αυτές στην Πολιτική Όρων Χρήσης, η οποία παρέχεται μέσω της Ιστοσελίδας, εκτός εάν ορίζεται διαφορετικά εδώ.</p>
            
                <h4>Συλλογή Προσωπικών Δεδομένων και Χρήση</h4>
                <p>Προκειμένου να σας εξοικειώσουμε με την Υπηρεσία, καθώς την χρησιμοποιείτε, ενδέχεται να σας ζητήσουμε να παράσχετε σε εμάς προσωπικά στοιχεία, συμπεριλαμβανομένων, ενδεικτικά, του ονόματος σας και του προσωπικού ηλεκτρονικού ταχυδρομείου σας.</p>
                <p>Οι πληροφορίες που συλλέγουμε με αυτόν τον τρόπο ενδέχεται να χρησιμοποιηθούν για να επικοινωνήσουμε μαζί σας ή να σας ταυτοποιήσουμε σύμφωνα με τους όρους της συγκεκριμένης Πολιτικής.</p>
            
                <h4>Χρήση των Προσωπικών δεδομένων για να λειτουργήσουν οι υπηρεσίες Άμεσης Προώθησης εμπορικών προϊόντων</h4>
                <p>Το Έργο διατηρεί το δικαίωμα να κοινοποιήσει τις συνδρομητικές υπηρεσίες του, εκδηλώσεις, και προγράμματα εκπαίδευσης και κατάρτισης για τους Χρήστες. Εάν ένας Χρήστης επιθυμεί οποιαδήποτε στιγμή να διακόψει την επικοινωνία με αυτό, θα πρέπει να στείλει ένα email στη διεύθυνση admin@agrinio.gr.</p>

                <h4>Ενημερωτικά Δελτία</h4>
                <p>Το Έργο διατηρεί το δικαίωμα, να σας στέλνει καινούργιες ενημερώσεις ή/και ενημερωτικά δελτία στη διεύθυνση email, που εσείς παρέχετε στην Ιστοσελίδα. Αυτές οι καινούργιες ενημερώσεις αποστέλλονται για ενημερωτικούς και διαφημιστικούς σκοπούς, με στόχο να σας ενημερώσουμε για τα νέα του «Περιβαλλοντικό Παρατηρητήριο Δήμου Αγρινίου», δραστηριότητες και εκδηλώσεις, τις οποίες πιστεύουμε ότι μπορούν να σας παρακινήσουν το ενδιαφέρον.</p>
                <p>Μπορείτε να εξαιρεθείτε από τα ενημερωτικά και προωθητικά μηνύματα, κάνοντας κλικ στην επιλογή «Απεγγραφή» από τα σχετικά email, ή στέλνοντας ένα mail στη διεύθυνση admin@agrinio.gr. Αυτός ο αποκλεισμός θα τεθεί σε ισχύ, αφού ολοκληρωθεί χρονικά η παραπάνω διαδικασία.</p>

                <h4>Ιδιωτικότητα στα Δεδομένα και Συμμόρφωση</h4>
                <p>Το Έργο δεν θα αποκαλύψει τα προσωπικά στοιχεία σας σε τρίτους, χωρίς την πρότερη συγκατάθεση σας, εκτός και αν στη παρούσα Πολιτική ορίζεται διαφορετικά, ή εάν αυτά ζητηθούν από κάποια αρμόδια δικαστική αρχή.</p>
                <p>Τα προσωπικά στοιχεία, τα οποία συλλέγονται από την Ιστοσελίδα μας, υποβάλλονται σε επεξεργασία και η διαχείριση τους γίνεται σύμφωνα με όλους τους ισχύοντες νόμους και κανονισμούς, και με βάση το απόρρητο που ισχύει για τα προσωπικά δεδομένα, συμπεριλαμβανομένου ενδεικτικά του GDPR.</p>
                <p>Παρέχοντας προσωπικά δεδομένα στο συγκεκριμένο Έργο, οι Χρήστες της Ιστοσελίδας δηλώνουν ρητά την συγκατάθεση τους για την επεξεργασία αυτών μέσω του Έργου, με διάφορους τρόπους, οι οποίοι περιγράφονται στην παρούσα Πολιτική.</p>
                <p>Οι χρήστες έχουν το δικαίωμα να ενημερώσουν, τροποποιήσουν, διαγράψουν και να ζητήσουν ένα αντίγραφο των προσωπικών δεδομένων τους, τα όποια είναι υποχρεωμένο το Έργο να διατηρεί. Εάν ένας Χρήστης επιθυμεί να ενημερώσει, να τροποποιήσει, να διαγράψει ή να λάβει ένα αντίγραφο των προσωπικών δεδομένων του, του δίνεται η δυνατότητα μέσω αποστολής ενός e-mail στο admin@agrinio.gr. Οι Ενημερώσεις, τροποποιήσεις ή διαγραφές προσωπικών δεδομένων θα ισχύσουν, αφού ολοκληρωθεί χρονικά η παραπάνω σχετική διαδικασία.</p>

                <h4>Καταγραφή Δεδομένων</h4>
                <p>Κάθε φορά που επισκέπτεστε την Ιστοσελίδα, ορισμένες πληροφορίες αποστέλλονται αυτόματα από το πρόγραμμα περιήγησης σας, με την μορφή δεδομένων καταγραφής. Τα δεδομένα καταγραφής μπορεί να περιλαμβάνουν πληροφορίες όπως, την διεύθυνση IP σας, την έκδοση του προγράμματος περιήγησης, τις σελίδες της Ιστοσελίδας μας που επισκεφτήκατε, το χρόνο και την ημερομηνία επίσκεψης σας, τον χρόνο που δαπανήσατε σε αυτές τις σελίδες, και άλλα στατιστικά τα οποία μπορούμε να συλλέξουμε εμείς.</p>

                <h4>Cookies</h4>
                <p>Τα Cookies είναι μικρά αρχεία δεδομένων και χαρακτηρίζονται ως ανώνυμα και μοναδικά αρχεία ταυτοποίησης. Αποστέλλονται στο πρόγραμμα περιήγησης σας από την Ιστοσελίδα, και αποθηκεύονται στο σκληρό δίσκο του υπολογιστή σας.</p>
                <p>Η Ιστοσελίδα χρησιμοποιεί Cookies για την συλλογή πληροφοριών, με στόχο να βελτιώσει την εμπειρία σας, όταν επισκέπτεστε αυτήν. Έχετε την δυνατότητα να αποδεχτείτε ή να απορρίψετε αυτά τα cookies. Εάν επιλέξετε να απορρίψετε αυτά, εσείς μπορεί να μην είστε ικανοί να χρησιμοποιήσετε κάποιες υπηρεσίες της Ιστοσελίδας.</p>

                <h4>Σύνδεση σε άλλες ιστοσελίδες</h4>
                <p>Η Ιστοσελίδα μπορεί να περιέχει συνδέσεις, οι οποίες παραπέμπουν σε άλλες ιστοσελίδες. Η λειτουργία των συγκεκριμένων ιστοσελίδων δεν εξαρτάται από εμάς, και ως εκ τούτου, σας συμβουλεύουμε να ελέγξετε την Πολιτική Απορρήτου τους πριν από την χρήση τους. Εμείς δεν έχουμε τον έλεγχο και δεν αναλαμβάνουμε καμία ευθύνη για τις πολιτικές απορρήτου, πρακτικές και το περιεχόμενο των Ιστοσελίδων ή υπηρεσιών, διαχειριζόμενων από τρίτους.</p>

                <h4>Πάροχοι υπηρεσιών</h4>
                <p>νδέχεται να συνεργαζόμαστε με εταιρίες τρίτων, ώστε να παρέχουμε τις Υπηρεσίες μας, και αυτή η συνεργασία έχει ως στόχο να διευκολύνει την ανάλυση του τρόπου με τον οποίο χρησιμοποιείται η υπηρεσία ή/και την εκτέλεση σχετικών υπηρεσιών. Κατά την εκτέλεση των καθηκόντων από αυτές τις εταιρίες, ενδέχεται οι συγκεκριμένες να έχουν πρόσβαση σε προσωπικά δεδομένα. Σε τέτοιες περιπτώσεις, εμείς θεωρούμαστε ως η εταιρία που ελέγχει τα δεδομένα, ενώ οι τρίτες είναι αυτές που τα επεξεργάζονται και δεσμεύονται να μην αποκαλύπτουν ή χρησιμοποιούν αυτά για οποιονδήποτε άλλο λόγο εκτός μόνο από αυτόν για τον οποίο προορίζονται. Συνεπώς, είναι υποχρεωμένες να συμμορφώνονται με όλους τους ισχύοντες νόμους και κανονισμούς, οι οποίοι σχετίζονται με το Απόρρητο των προσωπικών δεδομένων, συμπεριλαμβανομένου ενδεικτικά του Γενικού Κανονισμού για την Προστασία των Δεδομένων (GDPR).</p>

                <h4>Ασφάλεια</h4>
                <p>Το Έργο υιοθετεί και χρησιμοποιεί διεθνώς αποδεκτές πρακτικές ασφαλείας, πρωτόκολλα, εργαλεία, τεχνικές και τεχνολογίες, συμπεριλαμβανομένης της κρυπτογράφησης, προκειμένου να διατηρήσει τα προσωπικά δεδομένα σας ασφαλή και προστατευμένα από κάθε είδους μη εξουσιοδοτημένη πρόσβαση.</p>
                <p>Παρόλα αυτά, εσείς θα πρέπει να γνωρίζετε ότι, καμία μέθοδος, όπως η αποθήκευση ή μεταφορά δεδομένων μέσω του διαδικτύου, δεν μπορεί να είναι 100% ασφαλής και αξιόπιστη και επομένως δεν μπορούμε να εγγυηθούμε την απόλυτη ασφάλεια των δεδομένων που διατηρούμε.</p>
                <p>Πρέπει επίσης να είστε ενήμεροι ότι το Έργο δεν ασκεί κανέναν έλεγχο στα μέτρα ασφαλείας, τα οποία εφαρμόζονται από άλλες ιστοσελίδες, με τις οποίες αυτό μπορεί να αλληλεπιδρά και εσείς μπορείτε να τις επισκεφτείτε.</p>

                <h4>Προσωπικές πληροφορίες για Παιδιά</h4>
                <p>Η Ιστοσελίδα μας δεν απευθύνεται σε παιδιά κάτω των 13 ετών. Συνεπώς, εμείς δεν συλλέγουμε εν γνώσει μας προσωπικά στοιχεία ταυτοποίησης από παιδιά κάτω των 13 ετών. Εάν υποπέσει στην αντίληψη μας, ότι ένα τέτοιο παιδί μας έχει αποστείλει προσωπικά στοιχεία του, θα λάβουμε διορθωτικά μέτρα και θα διαγράψουμε τις πληροφορίες του. Εάν είστε γονέας ή κηδεμόνας και γνωρίζετε ότι το παιδί σας μας παρείχε προσωπικά στοιχεία, παρακαλούμε να επικοινωνήσετε με εμάς, έτσι ώστε να λάβουμε διορθωτικά μέτρα.</p>

                <h4>Ανανεώσεις στην Πολιτική Απορρήτου</h4>
                <p>Ενδέχεται να ενημερώνουμε την Πολιτική Απορρήτου κατά καιρούς και όποτε το κρίνουμε σκόπιμο. Οι αναθεωρήσεις και οι ενημερώσεις ενδέχεται να σχετίζονται με αλλαγές στις πρακτικές μας, με έναρξη νέων υπηρεσιών ή με αλλαγές που απαιτούνται για εναρμονισμό με τις απαιτήσεις του νομικού πλαισίου. Σας συμβουλεύουμε λοιπόν να ελέγχετε ανά τακτά χρονικά διαστήματα την συγκεκριμένη Πολιτική για να ενημερώνεστε για τυχόν αλλαγές.</p>
                <p>Οι αναθεωρημένες Πολιτικές Απορρήτου θα τεθούν σε ισχύ για τα τωρινά και τα μελλοντικά δεδομένα, από την ενδεδειγμένη ημερομηνία που αναφέρεται κάθε φορά στην παραπάνω Πολιτική. Όταν χρησιμοποιείτε την Ιστοσελίδα συνεχώς, αποδέχεστε τις αλλαγές στις αναθεωρημένες πολιτικές, χωρίς καμία επιφύλαξη.</p>

                <h4>Επικοινωνήστε με εμάς</h4>
                <p>Εάν έχετε ερωτήσεις σχόλια ή παράπονα σχετικά με αυτούς τους Όρους Χρήσης, μπορείτε να επικοινωνήσετε μαζί μας μέσω admin@agrinio.gr.</p>

                <h4>Έναρξη Εφαρμογής</h4>
                <p>Η συγκεκριμένη Πολιτική Απορρήτου ισχύει από τις 20 Ιουλίου του 2021.</p>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

TermsOfUse = connect(mapStateToProps)(TermsOfUse);

export default TermsOfUse;
