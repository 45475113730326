import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'thermiairDashboardShort',
		tooltip: 'thermiairDashboard',
		url: StaticRoutes.Dashboard,
		role: roles.GUEST
	},
	{
		name: 'thermiairMapShort',
		tooltip: 'thermiairMap',
		url: StaticRoutes.PollutionMap,
		role: roles.GUEST
	},
	{
		name: 'thermiairVariationShort',
		tooltip: 'thermiairVariation',
		url: StaticRoutes.PollutionVariation,
		role: roles.GUEST
	},
	{
		name: 'thermiairStatistics',
		tooltip: 'thermiairStatistics',
		url: StaticRoutes.Statistics,
		role: roles.GUEST
	},
	{
		name: 'thermiairCams',
		tooltip: 'thermiairCams',
		url: StaticRoutes.Cams,
		role: roles.GUEST
	},
	{
		name: 'thermiairTermsOfUse',
		tooltip: 'thermiairTermsOfUse',
		url: StaticRoutes.TermsOfUse,
		role: roles.GUEST
	}
];

export default menu;
