import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Container, Row, Col, CardGroup, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import { StaticRoutes } from '../../model/routes';
import T from 'modules/i18n';
import AirQualityIndex from '../../components/airqualityindex/airqualityindex'
import { requestData } from 'core/ducks/list'
import { Loading } from 'core/components'

import './css/thermiair.css'

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'AQI',
            refreshing: true,
            data: null
        };
        this.chooseTypeHandle = this.chooseTypeHandle.bind(this);
        this.setData();
        this.refreshingData()
    }

    setData() {
        this.setState({refreshing: true});
        this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {
            // console.log(this.props.dashboardDataList);
            this.setState({refreshing: false, data: this.props.dashboardDataList});
        });
    }

    refreshingData() {
        let t = this;
        try {
            setInterval(async () => {
                t.setData()
            }, 300000);
        } catch (e) {
            console.log(e);
        }
    }

    chooseTypeHandle(e) {
        this.setState({
                type: e.target.value
            },
            function () {
                this.setData()
            });

    }

    render() {

        const groups = [['thermiairAgrinio', ['Δημοτική Ενότητα Αγρινίου']],
            ['thermiairNeapoli', ['Δημοτική Ενότητα Νεάπολης']],
            ['thermiairThestieon', ['Δημοτική Ενότητα Θεστιέων']]];

        let idx = 0;

        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <CardGroup>
                                <Card>
                                    <CardBody className="text-center">
                                        <div>
                                            <Row className="justify-content-center">
                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label for="typeSelect" md={5}
                                                               size="lg"><h3><T>thermiairAirQualityIndex</T>
                                                        </h3></Label>
                                                        <Col md={4}>
                                                            <Input type="select" name="select"
                                                                   id="typeSelect"
                                                                   onChange={this.chooseTypeHandle}
                                                                   value={this.state.type}>
                                                                <option key="aqi" value={'AQI'}>AQI
                                                                </option>
                                                                <option key="pm1" value={'Two-channel PM1.0'}>PM1.0</option>
                                                                <option key="pm2.5" value={'Two-channel PM2.5'}>PM2.5
                                                                </option>
                                                                <option key="pm10" value={'Two-channel PM10'}>PM10</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <Col
                                                    className="thermiair-dashboard-class-1"><T>thermiairAirQualityIndexClass1</T>: 0-10</Col>
                                                <Col
                                                    className="thermiair-dashboard-class-2"><T>thermiairAirQualityIndexClass2</T>: 10-20</Col>
                                                <Col
                                                    className="thermiair-dashboard-class-3"><T>thermiairAirQualityIndexClass3</T>: 20-25</Col>
                                                <Col
                                                    className="thermiair-dashboard-class-4"><T>thermiairAirQualityIndexClass4</T>: 25-50</Col>
                                                <Col
                                                    className="thermiair-dashboard-class-5"><T>thermiairAirQualityIndexClass5</T>: 50+</Col>
                                            </Row>
                                            <Row className="justify-content-center font-italic">
                                                <Col><T>scale_comment</T></Col>
                                            </Row>
                                        </div>
                                        <hr/>
                                        {
                                            !this.state.refreshing ?
                                                <>
                                                    {groups.map((section) =>
                                                        <div className="thermiair-dashboard-div" key={section[0]}>
                                                            <h3><T>{section[0]}</T></h3>
                                                            <Row className="justify-content-center">
                                                                {this.state.data.map((item) =>
                                                                    section[1].includes(item.location.village) ?
                                                                        <Col sm="auto" key={item.location.village}>
                                                                            <Link to={{
                                                                                pathname: StaticRoutes.PollutionVariation,
                                                                                data: item
                                                                            }}>
                                                                                {
                                                                                    item.datastreams.forEach((obj, index) => {
                                                                                        let type = null;
                                                                                        if(this.state.type === 'AQI'){
                                                                                            type = 'Two-channel PM2.5'
                                                                                        }else{
                                                                                            type = this.state.type
                                                                                        }
                                                                                        if(obj.label === type){
                                                                                            idx = index
                                                                                        }
                                                                                    })


                                                                                }
                                                                                <AirQualityIndex id={item.label}
                                                                                                 title={item.description}
                                                                                                 timestamp={item.datastreams[idx].result_time}
                                                                                                 type={item.datastreams[idx].property.symbol}
                                                                                                 value={item.datastreams[idx].value}
                                                                                                 unit={item.datastreams[idx].unit.symbol}
                                                                                                 fixed={this.state.type === 'AQI' ? 0 : 2}
                                                                                />
                                                                            </Link>
                                                                        </Col>
                                                                        : ''
                                                                )}
                                                            </Row>
                                                            <hr/>
                                                        </div>
                                                    )}
                                                </>
                                                : <Loading/>
                                        }

                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
	profile: state.profile,
	notifications: state.notifications.messages,
	dashboardDataList: state.list.dashboardData.data,
	waitDashboardData: state.list.dashboardData.pending
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
