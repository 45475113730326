import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import StatisticsSection1 from './statisticsSection1';
import StatisticsSection2 from './statisticsSection2';
import classnames from 'classnames';

import './css/thermiair.css';


class Statistics extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };

        this.tabs = [{id: '1', title: 'Στατιστικά για όλους τους σταθμούς'}, {
            id: '2',
            title: 'Στατιστικά για ένα σταθμό'
        }];
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return (
            <div className="">
                <Container>
                    <Row className="justify-content-center">

                        <Col md="12">
                            <CardGroup>
                                <Card>
                                    <CardBody className="text-center">

                                        <Nav tabs>
                                            {this.tabs.map((item) =>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({active: this.state.activeTab === item.id})}
                                                        onClick={() => {
                                                            this.toggle(item.id);
                                                        }}
                                                    >
                                                        <b>{item.title}</b>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab}>

                                            <TabPane tabId="1">
                                                <StatisticsSection1/>
                                            </TabPane>

                                            <TabPane tabId="2">
                                                <StatisticsSection2/>
                                            </TabPane>

                                        </TabContent>

                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>

                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
});

Statistics = connect(mapStateToProps)(Statistics);

export default Statistics;
