import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'alert',
		icon: 'fa fa-bell-o',
		url: StaticRoutes.Alerts,
		role: roles.ADMIN
	},
];

export default menu;
